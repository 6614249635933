<template>
    <v-container fluid class="equipment-page-container">
        
        <v-overlay v-model="loading" class="align-center justify-center" absolute>
            <v-progress-circular
                color="white"
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col cols="12" md="4">
                <character-viewer></character-viewer>
            </v-col>
            <v-col cols="12" md="4">
                <inventory-container v-if="inventory_shown"></inventory-container>
                <color-picker-container v-if="colors_shown"></color-picker-container>
            </v-col>
            <v-col cols="12" md="4" >
                <equipment-container></equipment-container>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="text-right">
                <v-btn
                    class="mt-2"
                    size="x-large"
                    color="#9CF945"
                    @click="saveEquipment">Save</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import EquipmentContainer from './EquipmentContainerComponent';
import InventoryContainer from '../../inventory/components/InventoryContainerComponent';
import ColorPickerContainer from '../../inventory/components/ColorPickerContainerComponent';
import CharacterViewer from './CharacterViewerComponent';
import EquipmentController from "../controllers/EquipmentController";
import InventoryController from "../../inventory/controllers/InventoryController";

export default {
    components: {
        EquipmentContainer,
        InventoryContainer,
        ColorPickerContainer,
        CharacterViewer
    },
    data() {
        return {
        }
    },
    async created() {
        var currentItems = await EquipmentController.GetEquippedItems(this.$store.state.user.twitch_id);
        this.$store.dispatch('setEquippedItems', currentItems);
        var activeColors = await InventoryController.GetActiveColors(this.$store.state.user.twitch_id);
        console.log('created', activeColors);
        this.$store.dispatch('setActiveColors', activeColors);
    },
    computed: {
        loading() {
            return this.$store.state.equipment.loading;
        },
        inventory_shown() {
            return this.$store.state.inventory.shown;
        },
        colors_shown() {
            return this.$store.state.inventory.colors_shown;
        }
    },
    methods: {
        saveEquipment() {
            this.$store.commit('saveEquipment', {id: this.$store.state.user.twitch_id, colors: this.$store.state.inventory.active_colors});
        },
        
    }
}
</script>

<style>
    .equipment-page-container {
        padding: 30px;
        padding-top: 80px;
    }
    .equipment-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
    }
    .equipment-row {
        display: flex;
        justify-content: center;
    }
</style>