<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="text-center">
                <h1>Order Confirmation</h1>
            </v-col>
        </v-row>
        <v-row class="text-center">
            <v-col cols="12" class="order-confirm-loading" v-if="loading">
                <v-progress-circular
                    color="white"
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-col>
            <v-col cols="12" v-else>
                <div class="order-summary">
                    <h2>Thank you for your order!</h2>
                    <div class="order-items">

                        <ul class="order-confirmation-items">
                            <li class="order-confirmation-item" v-for="item in order?.cart?.cartItems" :key="item">
                                <label>{{item.display_name}}</label>
                                <v-spacer></v-spacer>
                                <label>{{'$' + item.price}}</label>
                            </li>
                        </ul>
                        <h3 class="order-confirmation-total">Total: {{'$' + order?.total}}</h3>
                    </div>
                    <v-btn 
                        color="lime"
                        to="/equipment"
                        >Equip your new items!</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="errorMessage">
            <v-col cols="12" class="text-center">
                <h1>ERROR - But first check your Equipment page to see if your item is there.</h1>
                <h2 style="color: #FF0000;">Error: Please email us at contact@chatfight.win</h2>
                <h3 style="color: #DD0000;"> {{ errorMessage }} </h3>
                <h4 style="color: #DD0000;">This is a new system so if there is a problem please let me know and I will get your money back to you!</h4>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

export default {
    data() {
        return {
            loading: false,
            errorMessage: false,
            order: {},
        }
    },
    computed: {

    },
    created() {
        console.log('route params');
        console.log(this.$route.query);
        this.loadOrderData();
    },
    methods: {
        loadOrderData() {
            var self = this;
            fetch(process.env.VUE_APP_API_URL + '/cart/postCheckout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'API_KEY': process.env.VUE_APP_API_KEY,
                    'API_SECRET': process.env.VUE_APP_API_SECRET
                },
                body: JSON.stringify({
                    twitch_id: self.$store.state.user.twitch_id,
                    session_id: self.$route.query.session_id
                })
            }).then(res => {
                if (res.ok) return res.json();
                return res.json().then(json => Promise.reject(json));
            }).then((response) => {
                console.log(response);
                if(response.success) {
                    self.order = response.data;
                    console.log('self.order', self.order);
                    self.loading = false;
                } else {
                    try {
                        self.errorMessage = response.message;
                    } catch (ex) {
                        self.errorMessage = 'Unknown Error';
                    }
                }
            }).catch(e => {
                self.errorMessage = e.message;
            });
			
        }
    },
}
</script>

<style scoped>
.order-summary {
    margin: auto;
    max-width: 400px;
    color: white;
    font-size: 18px;
}
.order-confirmation-items {
    margin-top: 40px;
    list-style: none;
}
.order-confirmation-item {
    display: flex;
    color: white;
}
.order-confirmation-total {
    margin-top: 40px;
    text-align: right;
}
.order-items {
    padding: 10px;
    background-color: #FFFFFF10;
    margin-bottom: 10px;
}


</style>