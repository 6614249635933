<template>
    <v-container>
       <v-row>
            <v-col cols="12">
                <h1>Order Canceled</h1>
            </v-col>
       </v-row>
    </v-container>    
</template>
<script>

export default {

}
</script>

<style scoped>

</style>