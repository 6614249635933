import { createStore } from 'vuex';
import InventoryModule from '@/modules/inventory/vuex/InventoryModule';
import EquipmentModule from '@/modules/equipment/vuex/EquipmentModule';
import UserModule from '@/modules/user/vuex/UserModule';
import CartModule from './modules/cart/vuex/CartModule';

const store = createStore({
    modules: {
        equipment: EquipmentModule,
        inventory: InventoryModule,
        user: UserModule,
        cart: CartModule,
    }
});


export default store;