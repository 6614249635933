<template>
    <div class="equipment-slot-outer" :class="{'selected': selected}">
        <div class="equipment-slot-inner" @click="selectSlot" :style="imageSource ? {backgroundImage: 'url(' + imageSource + ')'} : ''">
            <span class="equipment-type">{{type}}</span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'equipment-slot',
    props: [
        'type',
    ],
    data() {
        return {

        }
    },
    computed: {
        selected() {
            return this.$store.state.equipment.selected_slot == this.type;
        },
        equippedItem() {
            return this.$store.state.equipment.equipped_items[this.type] ?? null;
        },
        imageSource() {
            if(this.equippedItem != null && this.equippedItem.filename) {
                return this.equippedItem.filename ? require('@/assets/Equipment/' + this.equippedItem.equipment_type + '/' + this.equippedItem.name + '/' + this.equippedItem.name + '.png') : this.equippedItem.image_url
            }
            return this.equippedItem?.image_url ?? '';
        },
        // modelSource() {
        //     if(this.equippedItem != null && this.equippedItem.filename) {
        //         return this.equippedItem.filename ? require('@/assets/Equipment/' + this.equippedItem.equipment_type + '/' + this.equippedItem.name + '/' + this.equippedItem.name + '.fbx') : this.equippedItem.image_url
        //     }
        //     return this.equippedItem?.image_url ?? '';
        // }
    },
    created() {
        
    },
    methods: {
        selectSlot() {
            this.$store.dispatch('selectSlot', this.type);
        },
    }

}
</script>


<style type="text/css">
.equipment-slot-outer.selected .equipment-slot-inner {
    border: 5px solid #9CF945;
}
.equipment-slot-outer {
    padding: 10px;
    height: 150px;
    width: 150px;
    z-index: 2;
    position: relative;
}
.equipment-slot-inner {
    background: #00000080;
    width: 100%;
    height: 100%;
    border: 5px solid gray;
    border-radius: 8px;
    background-size: cover;
}
.equipment-slot-image {
    position: absolute;
    padding: 10px;
    left: 0;
    top: 0;
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
}
.equipment-type {
    display: none;
}
</style>