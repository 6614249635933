const UserModule = {
    state() {
        return {
            display_name: localStorage.display_name ?? '',
            twitch_id: localStorage.twitch_id ?? '',
            loggedIn: localStorage.token != null,
            owned_items: []
        }
    },
    mutations: {
        userLogin(state, data) {
            state.display_name = data.display_name;
            state.twitch_id = data.twitch_id;
            state.loggedIn = true;
        },
    },
    actions: {
        userLogin({ commit }, data) {
            commit('userLogin', data);
        },
    }
}

export default UserModule;