<template>
    <div class="inventory-slot-outer" :class="{'selected':selected}" @click="selectItem">
        <div class="inventory-slot-inner" :style="imageSource ? {backgroundImage: 'url(' + imageSource + ')'} : ''">
            <span class="inventory-item-name">{{item.name}}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'item'
    ],
    name: 'inventory-slot',
    data() {
        return {
            imageSource: this.item.filename ? require('@/assets/Equipment/' + this.item.equipment_type + '/' + this.item.name + '/' + this.item.name + '.png') : this.item.image_url
        }
    },
    created() {
    },
    computed: {
        selected() {
            return this.$store.state.inventory.selected_item == this.item
        },
    },
    methods: {
        selectItem() {
            this.$store.dispatch('selectInventoryItem', this.item)
        }

    }
}
</script>

<style>
.inventory-slot-outer.selected .inventory-slot-inner {
    border: 5px solid #9CF945;
}
.inventory-slot-outer {
    padding: 10px;
    width: 100%;
    height: inherit;
    position: relative;
    z-index: 1;
}
.inventory-slot-inner {
    background: #00000080;
    width: 100%;
    height: 100%;
    border: 5px solid gray;
    border-radius: 8px;
    background-size: cover;
}
.inventory-slot-image {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
}
.inventory-item-name {
    display: none;
}
</style>