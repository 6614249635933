<template>
  <v-col cols="12" sm="6" md="4" lg="3" class="d-flex justify-center">
    <v-card width="256px">
      <v-img
        :src="image_src"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="256px"
        width="256px"
        cover
      >
        <v-card-title
          class="text-white"
          v-text="item.display_name"
        ></v-card-title>
      </v-img>

      <v-card-actions>
        <v-card-text>{{ price_display }}</v-card-text>
        <v-spacer></v-spacer>
        <v-btn
          v-if="is_owned"
          size="small"
          color="surface-variant"
          class="is-owned-btn"
          variant="text"
          disabled
        >
          Owned
        </v-btn>
        <v-btn
          v-else-if="!is_in_cart"
          size="small"
          color="surface-variant"
          variant="text"
          @click="addToCart"
        >
          Add to cart
        </v-btn>
        <v-btn
          v-else
          size="small"
          color="surface-variant"
          class="in-cart-btn"
          variant="text"
          disabled
        >
          In Cart
          <v-icon class="in-cart-icon">mdi-check-bold</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
export default {
    props: ['item', 'owned_items'],
    data() {
        return {
        }
    },
    created() {
    },
    computed: {
        price_display() {
            try {
                return '$' + this.$props.item.price.toFixed(2);

            } catch (e) {
                console.log('error', e);
            }
            return 0;
        },

        is_in_cart() {
            return this.$store.state.cart.cart_items.find(e => e.item == this.item._id || e.item._id == this.item._id);
        },

        is_owned() {
            try {
                return this.owned_items.find(e => e == this.item._id);
            } catch(e) {
                console.log('e', e);
                return false;
            }
        },

        image_src() {
            return require('@/assets/Equipment/' + this.item.equipment_type + '/' + this.item.name + '/' + this.item.filename);
        }
    },
    methods: {
        addToCart() {
            this.$store.dispatch('addToCart', this.item);
            this.$store.dispatch('toggleMinicart');
        },
    },
}
</script>

<style scoped>
.in-cart-icon {
  font-size: 14px;
  margin-left: 10px;
  top: -1px;
}
.in-cart-btn {
  color: green !important;
}
.is-owned-btn {
  color: gray !important;
}
</style>
