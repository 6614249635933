import InventoryController from '../controllers/InventoryController';
const InventoryModule = {
    state() {
        return {
            shown: false,
            colors_shown: false,
            active_colors: {
                Body: '#FFFFFF',
                Head: '#FFFFFF',
                Weapon: '#FFFFFF',
                Hands: '#FFFFFF',
                Feet: '#FFFFFF',
            },
            equipment_type: null,
            loading: false,
            items: [],
            selected_item: null,
            type: null,
        }
    },
    mutations: {
        openInventory(state, type) {
            state.shown = true;
            state.colors_shown = false;
            state.equipment_type = type;
        },
        editColors(state) {
            state.shown = false;
            state.colors_shown = true;
        },
        closeInventory(state) {
            state.shown = false;
            state.equipment_type = '';
        },
        selectInventoryItem(state, item) {
            state.selected_item = item;
        },
        unequipInventoryItem(state, type) {
            state.selected_item = {};
            state.type = type;
        },
        applyColor(state, data) {
            state.active_colors[data.name] = data.color;
        },
        setActiveColors(state, colors) {
            console.log('setting active colors', colors);
            if(colors == undefined || colors == null || colors.length == 0) return;
            state.active_colors = colors;
        }
    },
    actions: {
        async openInventory({ commit, state }, data) {
            var items = await InventoryController.GetUserItemsOfType(data);
            state.items = items;
            commit('openInventory', data.type, items);
        },
        setActiveColors({ commit }, colors) {
            commit('setActiveColors', colors);
        },
        applyColor({ commit, }, data) {   
            commit('applyColor', data);
        },
        editColors({commit}) {
            commit('editColors');
        },
        closeInventory({ commit }) {
            commit('closeInventory');
        },
        selectInventoryItem({ commit, state }, item) {
            var type = state.equipment_type;
            commit('selectInventoryItem', item);
            commit('equipInventoryItem', { item, type });
        },
        unequipInventoryItem({ commit }, {item, type}) {
            commit('unequipInventoryItem', type);
            commit('equipInventoryItem', { item, type });
        }
    }
}

export default InventoryModule;