<template>
    <!-- Maybe have a grid of different items? -->
    <!-- and a filter system -->
    <v-container class="store-page-container">
        <v-row class="dddr">
            <store-item-card :key="item._id" v-for="item in store_items" :owned_items="owned_items" :item="item"></store-item-card>
        </v-row>
    </v-container>
</template>

<script>
import StoreItemCardComponent from './StoreItemCardComponent'
export default {
    components: {
        StoreItemCard: StoreItemCardComponent

    },
    data() {
        return {
            store_items: [],
            owned_items: [],
            errorMessage: '',
        }
    },
    created() {
        this.loadStore();
    },
    methods: {
        async loadStore() {
            let self = this;
			let items = await fetch(process.env.VUE_APP_API_URL + '/items/getStoreItems', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'API_KEY': process.env.VUE_APP_API_KEY,
					'API_SECRET': process.env.VUE_APP_API_SECRET
				},
				body: JSON.stringify({
                    twitch_id: localStorage.twitch_id,
				})
			}).then(res => {
                console.log('res', res);
				if (res.ok) return res.json();
				return res.json().then(json => Promise.reject(json));
			}).then((response) => {
                console.log('response', response);
                self.store_items = response.data.items;
                self.owned_items = response.data.owned_items;
                return response.data.items;
			}).catch(e => {
				console.log('error', e);
				self.errorMessage = e.message;
			});

            console.log('returning items', items);
        }
    }
}
</script>

<style>
.store-page-container {
    max-width: 1200px !important;
}
</style>