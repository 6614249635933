<template>
    <div class="equipment-container">
        <div class="equipment-backdrop backdrop" @click="unselectSlot"></div>
        <h1 @click="unselectSlot">Equipment</h1>
        <img 
            id="colorwheel-img" 
            :src="require('@/assets/Images/colorwheel.png')"
            @click="editColors">
        <div class="equipment-row">
            <equipment-slot
                type="Head"
            ></equipment-slot>
        </div>
        <div class="equipment-row">
            <!-- <equipment-slot
                type="Weapon"
            ></equipment-slot> -->
            <equipment-slot
                type="Hands"
            ></equipment-slot>
        </div>
        <div class="equipment-row">
            <equipment-slot
                type="Feet"
            ></equipment-slot>
        </div>
    </div>
</template>

<script>
import EquipmentSlot from './EquipmentSlotComponent'

export default {
    name: 'equipment-container',
    props: [
    ],
    components: {
        EquipmentSlot
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        editColors() {
            this.$store.dispatch('editColors');
        },
        unselectSlot() {
            this.$store.dispatch('selectSlot', '');
            this.$store.dispatch('closeInventory');
        }
    }
}
</script>

<style>
.backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000000C0;
    z-index: 0;
    border-radius: 4px;
}
.equipment-container {
    position: relative;
}
#colorwheel-img {
    width: 30px;
    position: absolute;
    left: 10px; 
    top: 10px;
    width: 30px;
    height: 30px;
    z-index: 1;
    cursor: pointer;
}
h1 {
    position: relative;
    z-index:1;
    color: white;
}
</style>