<template>
  <v-app class="background">
    <header-component></header-component>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app> 

</template>


<script>
import HeaderComponent from './modules/header/components/HeaderComponent';

export default {
  name: 'App',
  components: {
    HeaderComponent
  },
  metaInfo: {
    title: 'ChatFight!'
  }
}
</script>


<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.background {
    width: 100vw;
    height: 100% !important;
    min-height: 100vh !important;
    background-size: cover;
    background-attachment: fixed;
    background: linear-gradient(180deg, rgba(26,88,30,1) 0%, rgba(66,24,105,1) 100%);
}
.v-main__wrap {
  padding-top: 36px;
}
.v-btn--size-default {
  font-size: 16px;
}
.app-title {
  font-size: 28px;
}
html {
  overflow-y: auto !important;
}
</style>
