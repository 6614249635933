
export default {
    async SaveEquippedItems(items, data){
        console.log('data:', data);
        console.log('SaveEquippedItems', items);
        var url = process.env.VUE_APP_API_URL + '/users/equipped/save';
        fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'id': data.id,
                'items': JSON.parse(JSON.stringify(items)),
                'colors': data.colors
            })
        }).then(res => {
            console.log('success');
            console.log(res);
            return res.json();
        }).then(json => {

            console.log('success2:');
            console.log(json);

        }).catch(err => {
            console.log('error');
            console.log(err);
        })
    },
    async GetEquippedItems(id) {
        var url = process.env.VUE_APP_API_URL + '/users/equipped/get';
        var items = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'id': id
            })
        }).then(res => {
            console.log('success');
            console.log(res);
            return res.json();
        }).then(json => {

            console.log('success2:');
            console.log(json);
            // store.dispatch('setEquippedItems', json.items);
            return json.data;
        }).catch(err => {
            console.log('error');
            console.log(err);
        });

        return items;
    }
}
