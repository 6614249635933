export default {
    async addToCart(twitch_id, item_id) {
        var response = await fetch(process.env.VUE_APP_API_URL + '/cart/add_item/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API_KEY': process.env.VUE_APP_API_KEY,
                'API_SECRET': process.env.VUE_APP_API_SECRET
            },
            body: JSON.stringify({
                twitch_id: twitch_id,
                item_id: item_id
            })
        }).then(res => {
            console.log('res: ', res);
            if (res.ok) return res.json();
            return res.json().then(json => Promise.reject(json));
        }).then((items) => {
            console.log('items: ', items);
            return items.data;
        }).catch(e => {
            console.log('error: ', e);
        });

        return response;
    },
    async loadCart(twitch_id) {
        var response = await fetch(process.env.VUE_APP_API_URL + '/cart/find/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API_KEY': process.env.VUE_APP_API_KEY,
                'API_SECRET': process.env.VUE_APP_API_SECRET
            },
            body: JSON.stringify({
                twitch_id: twitch_id,
            })
        }).then(res => {
            console.log('res: ', res);
            if (res.ok) return res.json();
            return res.json().then(json => Promise.reject(json));
        }).then((items) => {
            console.log('items: ', items);
            return items.data;
        }).catch(e => {
            console.log('error: ', e);
        });

        return response;
    },
    async removeFromCart(twitch_id, item_id) {
        var response = await fetch(process.env.VUE_APP_API_URL + '/cart/remove_item/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API_KEY': process.env.VUE_APP_API_KEY,
                'API_SECRET': process.env.VUE_APP_API_SECRET
            },
            body: JSON.stringify({
                twitch_id: twitch_id,
                cart_item_id: item_id
            })
        }).then(res => {
            console.log('res: ', res);
            if (res.ok) return res.json();
            return res.json().then(json => Promise.reject(json));
        }).then((items) => {
            console.log('items: ', items);
            return items.data;
        }).catch(e => {
            console.log('error: ', e);
        });

        return response;
    },
}