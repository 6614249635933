
export default {
    async GetItemsOfType(type){
        console.log('type: ' + type);

        var items = [];
        var url = process.env.VUE_APP_API_URL + '/items/type';
        await fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'type': type
            })
        }).then(res => {
            return res.json();
        }).then(json => {
            items = json.data;
        }).catch(err => {
            console.log('error');
            console.log(err);
        })
        return items;
    },
    async GetUserItemsOfType(data){
        var type = data.type;
        var twitch_id = data.twitch_id;
        console.log('type: ' + type);

        var items = [];
        var url = process.env.VUE_APP_API_URL + '/items/owned/type';
        await fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                twitch_id: twitch_id,
                type: type
            })
        }).then(res => {
            return res.json();
        }).then(json => {
            items = json.data;
        }).catch(err => {
            console.log('error');
            console.log(err);
        })
        return items;
    },
    async SaveEquipment() {
        
    },
    async GetActiveColors(id) {
        var url = process.env.VUE_APP_API_URL + '/users/colors/get';
        var colors = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'id': id,
            })
        }).then(res => {
            console.log('success');
            console.log(res);
            return res.json();
        }).then(json => {

            console.log('success2:');
            console.log(json);
            // store.dispatch('setEquippedItems', json.items);
            return json.data;
        }).catch(err => {
            console.log('error');
            console.log(err);
        });

        return colors;
    }
}
