<template>
    <v-container class="homepage-container">
        <v-row>
            <v-col cols="12" class="homepage-header">
                <img class="homepage-logo" :src="require('@/assets/Images/ChatFightText.png')">
                <h2>A Battle Royale for stream VIEWERS</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4" class="d-flex justify-center">
                <v-card class="homepage-card" href="https://store.steampowered.com/app/2095570/ChatFight/">
                    <v-card-body>
                        <h2>Download Game</h2>
                    </v-card-body>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-center">
                <v-card class="homepage-card" to="/equipment">
                    <v-card-body>
                        <h2>Customize your Character</h2>
                    </v-card-body>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-center">
                <v-card class="homepage-card" to="/about">
                    <v-card-body>
                        <h2>How It Works</h2>
                    </v-card-body>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="text-center">
                <h3 class="donate-message">If you would like to support the development of ChatFight!</h3>
                <form action="https://www.paypal.com/donate" method="post" target="_top">
                    <input type="hidden" name="business" value="SGR4VZVY78GAW" />
                    <input type="hidden" name="no_recurring" value="0" />
                    <input type="hidden" name="item_name" value="Help support the development of ChatFight! 
                    (or buy me a coffee!) . . . (or beer)" />
                    <input type="hidden" name="currency_code" value="USD" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                </form>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
            users: []
        }
    },
    computed: {

    },
    created() {
        console.log('homepage');
    },
    methods: {
        goToAbout() {
            console.log('Going to about page');
        },
        testApiButton() {
            let self = this;
            var url = process.env.VUE_APP_API_URL + '/users/';
            let x = fetch(url, {
                method: 'get',
            }).then(res => {
                console.log('success');
                console.log(res);
                return res.json();
            }).then(json => {
                console.log('success2');
                self.users = json;

                console.log(self.users);

            }).catch(err => {
                console.log('error');
                console.log(err);
            })

            console.log(x);
        }
    }
}
</script>

<style scoped>
.donate-message {
    color: #CCC;
}
.homepage-card {
    background: #FFFFFF80;
    height: 300px;
    width: 300px;
    padding:10px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media screen and (min-width: 1400px) {
    .homepage-card {
        height: 400px;
        width: 400px;
    }
}
.homepage-card:hover {
    background: #FFFFFFB0;
    cursor: pointer;
}
.homepage-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.homepage-header {
    display: flex;
    flex-direction: column;
    justify-content: center;;
}
.homepage-header > h2 {
    color: #CCC;
}
.homepage-logo {
    width: 400px;
    align-self: center;
}
</style>