<template>
    <div class="inventory-container" v-if="equipmentType">
        <div class="inventory-backdrop backdrop"></div>
        <h1>{{equipmentType}} Items</h1>
        <div class="inventory-slot-container">
            <inventory-slot
                v-for="item in items"
                :item="item"
                :key="item"
            ></inventory-slot>
            <inventory-empty-slot :type="type"></inventory-empty-slot>
        </div>
    </div>    
</template>

<script>
import InventorySlot from './InventorySlotComponent'
import InventoryEmptySlot from './InventoryEmptySlotComponent'

export default {
    components: {
        InventorySlot,
        InventoryEmptySlot
    },
    name: 'inventory-container',
    computed: {
        type() {

            return this.$store.state.inventory.equipment_type;
        },
        equipmentType() {
            return this.type.charAt(0).toUpperCase() + this.type.slice(1);
        },
        items() {
            return this.$store.state.inventory.items;
        }
    }
}
</script>

<style>
.inventory-container {
    position: relative;
}
.inventory-slot-container {
    z-index: 1;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-auto-rows: 1fr;
}
@media screen and (max-width: 500px) {
    .inventory-slot-container {
        grid-template-columns: 50% 50%;
    }
}

.inventory-slot-container::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
}

.inventory-slot-container > *:first-child{
    grid-row: 1 / 1;
    grid-column: 1 / 1;
}

</style>