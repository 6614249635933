<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1>What up</h1>
            </v-col>
        </v-row>   
        <v-row>
            <v-col cols="3">
                <h2>Add Item</h2>
                <v-text-field
                    v-model="item.display_name"
                    label="Display Name"></v-text-field>
                <v-text-field 
                    v-model="item.name" 
                    label="Item Name"></v-text-field>
                <v-text-field
                    v-model="item.equipment_type" 
                    label="Equipment Type"
                    placeholder="['Head', 'Hands', 'Feet']"></v-text-field>
                <v-text-field
                    v-model="item.price"
                    label="Price"
                    placeholer="4.99"></v-text-field>
                <v-btn 
                    color="lime"
                    @click="saveItem">Save</v-btn>
                <label v-if="success" color="green">Saved Successfully</label>
                <label v-if="failed" color="red">Failed to Save Item</label>
            </v-col>
            <v-col cols="3">
                <v-btn
                    color="lime"
                    @click="updateSchema">Update Schema</v-btn>
                <br>
                <br>
                <v-btn
                    color="lime"
                    @click="cleanUpDuplicateItems">Clean Up Duplicates</v-btn>
            </v-col>
        </v-row> 
    </v-container>    
</template>

<script>

export default {
    data() {
        return {
            item: {

            },
            success: false,
            failed: false,
        }
    },
    created() {

    },
    methods: {
        cleanUpDuplicateItems() {
            var self = this;
            fetch(process.env.VUE_APP_API_URL + '/items/removeDuplicateItemsForAllUsers', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'API_KEY': process.env.VUE_APP_API_KEY,
                    'API_SECRET': process.env.VUE_APP_API_SECRET
                }
            }).then(res => {
                if (res.ok) return res.json();
                return res.json().then(json => Promise.reject(json));
            }).then(({ success }) => {
                console.log('success');
                if(success) {
                    self.success = true;
                    self.failed = false;
                } else {
                    self.failed = true;
                    self.success = false;
                }
                setTimeout(() => {
                    self.failed = false;
                    self.success = false;
                }, 5000);
            }).catch(e => {
                console.log('error', e);
                self.errorMessage = e.message;
            });
        },
        saveItem() {
            console.log('item:', this.item);
            console.log('twitch_id:', localStorage.twitch_id);
            var self = this;
            this.item.filename = this.item.name + '.png';
			fetch(process.env.VUE_APP_API_URL + '/items/add', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'API_KEY': process.env.VUE_APP_API_KEY,
					'API_SECRET': process.env.VUE_APP_API_SECRET
				},
				body: JSON.stringify({
                    twitch_id: localStorage.twitch_id,
					item: self.item
				})
			}).then(res => {
				if (res.ok) return res.json();
				return res.json().then(json => Promise.reject(json));
			}).then(({ success }) => {
                if(success) {
                    self.success = true;
                    self.failed = false;
                } else {
                    self.failed = true;
                    self.success = false;
                }
                setTimeout(() => {
                    self.failed = false;
                    self.success = false;
                }, 5000);
			}).catch(e => {
				console.log('error', e);
				self.errorMessage = e.message;
			});
        },
        updateSchema() {

			fetch(process.env.VUE_APP_API_URL + '/items/updateAll', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'API_KEY': process.env.VUE_APP_API_KEY,
					'API_SECRET': process.env.VUE_APP_API_SECRET
				},
				body: JSON.stringify({
                    twitch_id: localStorage.twitch_id,
				})
			}).then(res => {
				if (res.ok) return res.json();
				return res.json().then(json => Promise.reject(json));
			}).then(({ success }) => {
                if(success) {
                    self.success = true;
                    self.failed = false;
                } else {
                    self.failed = true;
                    self.success = false;
                }
                setTimeout(() => {
                    self.failed = false;
                    self.success = false;
                }, 5000);
			}).catch(e => {
				console.log('error', e);
				self.errorMessage = e.message;
			});
        }
    }
}
</script>

<style scoped>
h2 {
    color: white;
}

</style>
