
import * as VueRouter from 'vue-router'

import HomePageComponent from './components/HomePageComponent.vue'
import AboutPageComponent from './components/AboutPageComponent.vue'
import LoginPageComponent from './components/LoginPageComponent.vue'
// import DashboardPageComponent from './components/DashboardPageComponent.vue'
import StorePageComponent from './components/StoreComponents/StorePageComponent.vue'
import EquipmentPageComponent from './modules/equipment/components/EquipmentPageComponent'
import OrderConfirmComponent from './modules/cart/components/OrderConfirmComponent'
import OrderCancelComponent from './modules/cart/components/OrderCancelComponent'
import AdminPageComponent from './modules/admin/components/AdminPageComponent'

function authenticateUser(to, from, next) {
    if(localStorage.token) {
        next();
    } else {
        next('login');
    }
}

function authenticateAdmin(to, from, next) {
    console.log(localStorage);
    if(localStorage.token && 
        (localStorage.twitch_id == "814435365" || localStorage.twitch_id == "787731957" || localStorage.twitch_id == "31914832")) {
        next();
    } else {
        next('');
    }
}

const routes = [
    {
        path: '/',
        component: HomePageComponent
    },
    {
        path: '/about',
        component: AboutPageComponent,
    },
    {
        path: '/login',
        component: LoginPageComponent,
    },
    // {
    //     path: '/dashboard',
    //     component: DashboardPageComponent,
    //     beforeEnter: authenticateUser
    // },
    {
        path: '/store',
        component: StorePageComponent,
        beforeEnter: authenticateUser
    },
    {
        path: '/equipment',
        component: EquipmentPageComponent,
        beforeEnter: authenticateUser
    },
    {
        path: '/order_confirm',
        component: OrderConfirmComponent,
        beforeEnter: authenticateUser
    },
    {
        path: '/order_cancel',
        component: OrderCancelComponent,
        beforeEnter: authenticateUser
    },
    {
        path: '/acp',
        component: AdminPageComponent,
        beforeEnter: authenticateAdmin
    }
]

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes
});

export default router;