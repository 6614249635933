<template>
    <v-container class="about-container">
        <h1>What is ChatFight?</h1>
        <p>ChatFight! is a battle royale for <strong>Twitch VIEWERS</strong>. It is an exciting way for Twitch streamers to interact with their viewers! It is great for giveaways, or just a fun and friendly competition based on nothing but RNG.</p>
        <br>
        <h2>How do I play?</h2> 
        <p>The streamer will start the game, type in their twitch username, and then press Play. 
            Then a lobby will be created for their viewers to join. </p>
            
        <br>
            <h3>To join a game, type <strong>!join</strong> in the streamer's Twitch Chat</h3>
        <p>Once the lobby is sufficiently full, the streamer can start the game, and then the battle begins!
             There is absolutely no strategy or skill to determine who wins the fight, it is all based on pure RNG.</p>

             <br><br>

        <h1>Who am I?</h1>
        <p>My name is Kelvin Newton, and I am just a guy that enjoys playing games, watching streams, and making games. If you have any feedback for me please let me know at contact@chatfight.win!</p>

            <br><br>
        <h1>Donations</h1>
        <p>If you would like to support the development of ChatFight!</p>
        <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="business" value="SGR4VZVY78GAW" />
            <input type="hidden" name="no_recurring" value="0" />
            <input type="hidden" name="item_name" value="Help support the development of ChatFight! 
            (or buy me a coffee!) . . . (or beer)" />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
    </v-container>
</template>

<script>
console.log("Version 1.1.0");
export default {
    
}
</script>

<style scoped>
.about-container {
    color: white;
    max-width: 600px;
}
p {
    color: #AAA;
}
</style>
