<template>
	<v-list-item class="cart-item-container">
		<div class="cart-item-image-container" :href="item.url">
			<img class="cart-item-image" :src="image_src" :alt="item.display_name">
		</div>
		<div class="cart-item-details-container">
			<span class="cart-item-name">{{item.display_name}}</span>
			<span class="cart-item-price">Price: ${{item.price}}</span>
		</div>
		<div class="cart-item-delete-container">
			<div class="text-center">
				<v-dialog
					v-model="remove_item_menu">
					<template v-slot:activator="{ props }">
						<v-icon
							v-bind="props"
							class="remove-from-cart-btn"
							>mdi-trash-can</v-icon>
					</template>
						<v-card>
							<v-card-title class="remove-from-cart-header">
								Remove From Cart?
							</v-card-title>

							<v-card-text class="remove-from-cart-text">
								Are you sure you want to remove <strong>{{item.name}}</strong> from the cart?
							</v-card-text>

							<v-divider></v-divider>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									class="remove-from-cart-cancel"
									@click="remove_item_menu = false;">
									No
								</v-btn>
								<v-btn
									class="remove-from-cart-submit"
									text
									@click="removeFromCart(); remove_item_menu = false;"
								>
									Yes, Remove
								</v-btn>
							</v-card-actions>
						</v-card>
				</v-dialog>
			</div>
		</div>
	</v-list-item>
</template>

<script>
export default {
	props: [
		'item'
	],
	data() {
		return {
			remove_item_menu: false,
		}
	},
	computed: {
        image_src() {
            return require('@/assets/Equipment/' + this.item.equipment_type + '/' + this.item.name + '/' + this.item.name + '.png');
        }
	},
	methods: {
		removeFromCart() {
			this.$store.dispatch('removeFromCart', this.item);
		}
	}
}
</script>

<style scoped>
.cart-item-container {
	display: flex;
}
.cart-item-image-container {
	flex: 1;
	width: 100%;
}
.cart-item-image {
	width: 100%;
}
.cart-item-details-container {
	flex: 3;
	display: flex;
	flex-direction: column;
	text-align: left;
	padding-left: 10px;
}
.cart-item-name {
	font-weight: bold;
}
.cart-item-price {
	font-size: 14px;
	color: gray;
}
.remove-from-cart-btn {
	cursor: pointer;
}
.remove-from-cart-btn:hover {
	color: gray;
}

.remove-from-cart-header {
	color: white;
	font-size: 24px;
	background-color: rgb(var(--v-theme-primary));
}
.remove-from-cart-cancel {
	color: black;
	background-color: white;
}
.remove-from-cart-submit {
	color: white;
	background-color: rgb(var(--v-theme-primary));
	--v-theme-overlay-multiplier: var(--v-theme-primary-overlay-multiplier);
}
</style>