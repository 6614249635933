<template>
    <div class="color-picker-container">
        <h1>Edit Colors</h1>
        <v-row class="color-picker-row" v-for="(color, name) in colors" :key="name" v-show="name != 'Weapon'">
            <v-col cols="8">
                <h1 class="color-slot-name">{{name}}:</h1>
            </v-col>
            <v-col cols="4" class="z1">
                <v-menu
                    class="color-picker-menu"
                    :ref="name + '_menu'"
                    v-model="menus[name]"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    >
                    <template v-slot:activator="{ props }">
                        <v-btn
                            :id="name + '_color'"
                            :color="colors[name]"
                            @click="setCurrentColor(name)"
                            v-bind="props"
                        ></v-btn>
                    </template>
                    <v-color-picker
                        v-model="currentColor"
                        :modes="['hex']"
                    >
                    </v-color-picker>
                    <v-btn
                        flat
                        color="primary"
                        class="color-picker-apply-btn"
                        @click="applyColor(name)"
                    >Apply</v-btn>
                </v-menu>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    data() {
        return {
            menus: {
                Body: false,
                Head: false,
                Weapon: false,
                Hands: false,
                Feet: false,
            },
            currentColor: '#FFFFFF'
        }
    },
    computed: {
        colors() {
            var activeColors = this.$store.state.inventory.active_colors;
            return {
                Body: activeColors['Body'] ?? '#FFFFFF',
                Head: activeColors['Head'] ?? '#FFFFFF',
                Weapon: activeColors['Weapon'] ?? '#FFFFFF',
                Hands: activeColors['Hands'] ?? '#FFFFFF',
                Feet: activeColors['Feet'] ?? '#FFFFFF'
            };
        }
    },
    methods: {
        setCurrentColor(name) {
            this.currentColor = this.colors[name];
        },
        applyColor(name) {
            this.menus[name] = false;
            if(!this.currentColor.includes("#")) {
                this.currentColor = '#' + this.currentColor;
            }
            if(this.currentColor.length > 7) {
                this.currentColor = this.currentColor.substr(0, 7);
            }
            console.log('calling applyColor', {name: name, color: this.currentColor});
            this.$store.dispatch('applyColor', {name: name, color: this.currentColor});
            this.currentColor = '#FFFFFF';
        },
        applyColors() {
            this.$store.dispatch('applyColors', this.colors);
        }
    }
}
</script>

<style>
.color-picker-container {
    position: relative;
    background-color: #000000C0;
    border-radius: 4px;
}
.color-picker-menu {
    right: 0;
}
.color-picker-row {
    z-index: 1;
    position: relative;
}
.z1 {
    z-index: 1;
    position: relative;
}
.color-picker-apply-btn {
    width: 50px;
    position: absolute;
    bottom: 3px;
    right: 3px;
}
@media screen and (max-width: 959px) {
    .color-picker-menu > div {
        left: auto !important;
        right: 0 !important;
    }
}

</style>