const EquipmentPositions = {
    Head: {
        default: {
            x: 0, y: 1.75, z: -0.04, s: .01
        },
        Sideburns: {
            x: 0, y: 1.72, z: -0.07, s: 0.0056
        },
        Headphones: {
            x: 0, y: 1.73, z: -0.07, s: 0.0056
        },
        CheesyGlasses: {
            x: 0, y: 1.65, z: -0.04, s: .012
        },
        TopHat: {
            x: 0, y: 1.87, z: -0.01, s: .011
        },
        Horns: {
            x: 0, y: 1.70, z: -0.02, s: .01
        }

    },
    Weapon: {
        default: {
            x: 0, y: 0, z: 0, s: .01
        }
    },
    LeftHand: {
        default: {
            x: .93, y: 1.26, z: .02, s: .01
        }
    },
    RightHand: {
        default: {
            x: -.93, y: 1.26, z: .02, s: .01
        }
    },
    LeftFoot: {
        default: {
            x: .15, y: .15, z: .05, s: .01
        }
    },
    RightFoot: {
        default: {
            x: -.15, y: .15, z: .05, s: .01
        }
    },
}

export function getEquipmentPosition(type, name) {
    return EquipmentPositions[type][name] ?? EquipmentPositions[type]['default'];
}