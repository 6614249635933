<template>
    <div class="header-container">
        <v-navigation-drawer v-model="sidebar" app class="d-lg-none">
            <v-list-item class="flex-column">
                <v-list-item-title class="text-h6 text-left"><img class="header-logo" :src="require('@/assets/Images/ChatFightText.png')"></v-list-item-title>
                <v-list-item-subtitle class="text-left" v-if="display_name">Welcome, {{ display_name }}</v-list-item-subtitle>
            </v-list-item>
            <v-divider></v-divider>
            <v-list>
                <v-list-item
                    v-for="item in navItems"
                    :key="item.name"
                    :to="item.url"
                    link>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar app class="header-bar">
            <template v-slot:prepend>
                <span class="d-lg-none">
                    <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
                </span>
            </template>
            <v-toolbar-title class="app-title" v-if="display_name">
                <img class="header-logo" :src="require('@/assets/Images/ChatFightText.png')">
                Welcome, {{ display_name }}
            </v-toolbar-title>
            <v-toolbar-title class="app-title" v-else>
                <img class="header-logo" :src="require('@/assets/Images/ChatFightText.png')">
            </v-toolbar-title>
            <div class="app-bar-items hidden-xs hidden-sm hidden-md">
                <v-btn
                    flat
                    v-for="item in navItems"
                    :key="item.name"
                    class="pl-6 pr-6"
                    :to="item.url">
                {{ item.name }}
                </v-btn>
                <v-btn
                    v-if="loggedIn"
                    flat
                    @click="logout"
                >Logout</v-btn>
            </div>
            <template v-slot:append>
                <v-app-bar-nav-icon icon style="margin-left: 50px;" @click="toggleMinicart"><v-icon>mdi-cart</v-icon></v-app-bar-nav-icon>
            </template>
        </v-app-bar>
        <v-navigation-drawer 
            v-model="$store.state.cart.minicart_open" 
            app 
            fixed
            temporary
            position="right" 
            class="cart-drawer">
            <cart-drawer></cart-drawer>
        </v-navigation-drawer>
    </div>
</template>

<script>
import CartComponent from '@/modules/cart/components/CartComponent';

export default {
    components: {
        CartDrawer: CartComponent
    },
    data() {
        return {
            sidebar: false,
            site_name: 'ChatFight!',
            loggedInNavItems: [
                {
                    name: 'Home',
                    url: '/',
                    icon: 'home'
                },
                {
                    name: 'Equipment',
                    url: '/equipment',
                    icon: 'helmet'
                },
                {
                    name: 'Store',
                    url: '/store',
                    icon: 'store',
                },
                {
                    name: 'About',
                    url: '/about',
                    icon: 'face'
                }
            ],
            loggedOutNavItems: [
                {
                    name: 'Home',
                    url: '/',
                    icon: 'home'
                },
                {
                    name: 'About',
                    url: '/about',
                    icon: 'face'
                },
                {
                    name: 'Login',
                    url: '/login',
                    icon: 'helmet'
                }
            ]
        }
    },
    computed: {
        display_name() {
            return this.$store.state.user.display_name;
        },
        loggedIn() {
            return this.$store.state.user.loggedIn;
        },
        navItems() {
            return this.loggedIn ? this.loggedInNavItems : this.loggedOutNavItems;
        },
        minicart() {
            return this.$store.state.cart.minicart_open;
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        logout() {
            localStorage.clear();
            window.location.reload();
        },
        toggleMinicart() {
            
            this.$store.dispatch('toggleMinicart', this.$store.state.user.twitch_id);
        }
    },
}
</script>

<style>
.header-bar {
    background-color: #000000c0 !important;
    color: white !important;
    height: 100px !important;
    justify-content: center;
}
.app-title {
    text-align: left;
    width: 100%;
}
.app-title > div {
    display: flex;
    align-items: center;
}
.header-logo {
    width: 200px;
}
.cart-drawer {
    margin-top: 0 !important;
    height: 100vh !important;
    z-index: 2000 !important;
    width: 300px !important;
    position: fixed;
}
.cart-header {
    padding: 32px;
    color: #111;
}
</style>