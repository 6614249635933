<template>
  <div class="character-viewer-container">
    <div class="character-scene-container" ref="sceneContainer"></div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { getEquipmentPosition } from '../EquipmentPositions'

export default {
    props: [
        'character'
    ],
    name: 'character-viewer',
    data() {
        return {
            scene: null,
            renderer: null,
            equippedItems: [],
            fbxLoader: null,
        }
    },
    computed: {
        colors() {
            return this.$store.state.inventory.active_colors;
        }
    },
    mounted() {
        this.setUpScene();
        var self = this;
        this.$store.subscribe((mutation) => {
            switch(mutation.type) {
                case 'selectInventoryItem':
                    self.equipItem(mutation.payload.equipment_type, mutation.payload.name)
                    break;
                case 'setEquippedItems':
                    self.equipItems();
                    break;
                case 'applyColor':
                    console.log('applying color');
                    self.reloadScene();
                    break;
                case 'setActiveColors':
                    console.log('cv setActiveColors');
                    self.reloadScene();
                    break;
                case 'unequipInventoryItem':
                    self.equipItem(mutation.payload, null);
                    break;
            }
        })
    },
    methods: {
        reloadScene() {
            this.clearScene();
            this.setUpScene();
            this.equipItems();
        },
        clearScene() {
            this.scene.remove.apply(this.scene, this.scene.children);
        },
        equipItems() {
            var items = this.$store.state.equipment.equipped_items;
            for(var [type, item] of Object.entries(items)) {
                this.equipItem(type, item.name);
            }
        },
        equipItem(type, name) {
            var color = this.colors[type];
            if(color == null || color.length === 0) {
                color = '#FFFFFF';
            }
            switch(type) {
                case 'Hands':
                    if(this.equippedItems['LeftHand'] != null) {
                        this.scene.remove(this.equippedItems['LeftHand'].object);
                    }
                    if(this.equippedItems['RightHand'] != null) {
                        this.scene.remove(this.equippedItems['RightHand'].object);
                    }
                    break;
                case 'Feet':
                    if(this.equippedItems['LeftFoot'] != null) {
                        this.scene.remove(this.equippedItems['LeftFoot'].object);
                    }
                    if(this.equippedItems['RightFoot'] != null) {
                        this.scene.remove(this.equippedItems['RightFoot'].object);
                    }
                    break;
                default:
                    if(this.equippedItems[type] != null) {
                        this.scene.remove(this.equippedItems[type].object);
                    }
            }

            if(name == null) return;

            let path = '';
            let position = {};

            switch(type) {
                case 'Hands':
                    // Load Left Hand first
                    path = require('@/assets/Equipment/Hands/' + name + '/' + name + '_L.fbx');
                    position = getEquipmentPosition('LeftHand', name);
                    this.loadItemIntoScene(path, position, 'LeftHand', color);

                    // Then load Right Hand
                    path = require('@/assets/Equipment/Hands/' + name + '/' + name + '_R.fbx');
                    position = getEquipmentPosition('LeftHand', name);
                    this.loadItemIntoScene(path, position, 'RightHand', color);
                    break;

                case 'Feet':
                    // Load Left Foot first
                    path = require('@/assets/Equipment/Feet/' + name + '/' + name + '_L.fbx');
                    position = getEquipmentPosition('LeftFoot', name);
                    this.loadItemIntoScene(path, position, 'LeftFoot', color);

                    // Then load Right Hand
                    path = require('@/assets/Equipment/Feet/' + name + '/' + name + '_R.fbx');
                    position = getEquipmentPosition('RightFoot', name);
                    this.loadItemIntoScene(path, position, 'RightFoot', color);
                    break;
                default:
                    path = require('@/assets/Equipment/' + type + '/' + name + '/' + name + '.fbx');
                    position = getEquipmentPosition(type, name);
                    this.loadItemIntoScene(path, position, type, color)
            }
        },
        loadItemIntoScene(path, position, type, color) {

            try {
                // Default blue material
                const material = new THREE.MeshStandardMaterial();
                material.color = new THREE.Color(color);

                // Load FBX file into the scene
                this.fbxLoader.load(
                    path,
                    (object) => {
                        object.traverse(function (child) {
                            if (child.isMesh) {
                                child.material = material
                                if (child.material) {
                                    child.material.transparent = false
                                }
                            }
                        })
                        object.scale.set(position.s, position.s, position.s)
                        object.position.set(position.x, position.y, position.z);

                        // Save it to the array of items that are currently equipped
                        this.equippedItems[type] = {
                            path: path,
                            object: object
                        }
                        this.scene.add(object)
                    },
                    (xhr) => {
                        console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                    },
                    (error) => {
                        console.log(error)
                    }
                )
            } catch(e) {
                console.log('error');
                console.log(e);
            }
        },
        loadCharacter(scene) {
            var color = this.$store.state.inventory.active_colors['Body'];
            const material = new THREE.MeshStandardMaterial()
            material.color = new THREE.Color(color != null && color.length > 0 ? color : '#5577FF')
            this.fbxLoader = new FBXLoader()
            this.fbxLoader.load(
                require('@/assets/Character/RigifyStickman.fbx'),
                (object) => {
                    object.traverse(function (child) {
                        if (child.isMesh) {
                            child.material = material
                            if (child.material) {
                                child.material.transparent = false
                            }
                        }
                    })
                    object.scale.set(.01, .01, .01)
                    scene.add(object)
                },
                (xhr) => {
                    console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            )
        },
        setUpScene() {

            // Get a reference to the container element that will hold our scene
            const container = this.$refs['sceneContainer'];
            console.log('container', container);
            container.innerHTML = '';

            var scene = this.scene;
            scene = new THREE.Scene();

            // Set the background color
            scene.background = null;

            // Create a camera
            const fov = 35; // AKA Field of View
            const aspect = container.clientWidth / container.clientHeight;
            const near = 0.1; // the near clipping plane
            const far = 100; // the far clipping plane

            const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

            // every object is initially created at ( 0, 0, 0 )
            // move the camera back so we can view the scene
            camera.position.set(0.8, 1.4, 7.0);

            const light = new THREE.PointLight()
            light.position.set(15, 5.4, 10.0)
            scene.add(light)
            const light1 = new THREE.PointLight()
            light1.position.set(-15, 5.4, -10.0)
            scene.add(light1)


            // create a Mesh containing the geometry and material
            this.loadCharacter(scene);

            // create the renderer
            var renderer = this.renderer ?? new THREE.WebGLRenderer({alpha: true});

            renderer.setClearColor(0x000000, 0.75);

            // next, set the renderer to the same size as our container element
            renderer.setSize(container.clientWidth, container.clientHeight);

            // finally, set the pixel ratio so that our scene will look good on HiDPI displays
            renderer.setPixelRatio(window.devicePixelRatio);

            // add the automatically created <canvas> element to the page
            container.append(renderer.domElement);


            const controls = new OrbitControls(camera, renderer.domElement)
            controls.enableDamping = true
            controls.target.set(0, 1, 0)
            // controls.autoRotate = true
            // controls.autoRotateSpeed *= 5;

            // render, or 'create a still image', of the scene

            function animate() {
                requestAnimationFrame(animate)

                controls.update()

                render()

            }

            function render() {
                renderer.render(scene, camera)
            }

            animate()

            this.scene = scene;
            this.renderer = renderer;



        }
    }
}
</script>

<style>
.character-viewer-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
}
.character-scene-container {
  width: 100%;
  height: 100%;
  min-height: 400px;
  border-radius: 4px;
  overflow: hidden;
}
</style>
