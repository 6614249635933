import CartController from '../controllers/CartController'

const CartModule = {
    state() {
        return {
            cart_items: [],
            total: 0,
            minicart_open: false,
            minicart_loading: false,
            cart_id: 0,
            item_ids_in_cart: [],
        }
    },
    mutations: {
        async addToCart(state, data) {
            console.log('cartModule->addToCart');
            state.minicart_loading = true;
            CartController.addToCart(data.twitch_id, data.item._id).then((response) => {
                state.cart_items = response.cartItems;
                state.cart_id = response._id;
                localStorage.setItem('cart_id', response._id);
                state.minicart_loading = false;
            })
        },
        async toggleMinicart(state, twitch_id) {
            console.log('cartModule->toggleMinicart');
            state.minicart_open = !state.minicart_open;
            if(state.minicart_open) {
                state.minicart_loading = true;
                CartController.loadCart(twitch_id).then((response) => {
                    state.cart_items = response.cartItems;
                    state.cart_id = response._id;
                    localStorage.setItem('cart_id', response._id);
                    state.minicart_loading = false;
                });
            }
        },
        async removeFromCart(state, data) {
            console.log('cartModule->removeFromCart');
            state.minicart_loading = true;
            CartController.removeFromCart(data.twitch_id, data.item).then((response) => {
                state.cart_items = response.cartItems;
                state.cart_id = response._id;
                localStorage.setItem('cart_id', response._id);
                state.minicart_loading = false;
            });
        },
        async loadCartData(state, twitch_id) {
            console.log('cartModule->loadCartData');
            state.minicart_loading = true;
            CartController.loadCart(twitch_id).then((response) => {
                state.cart_items = response.cartItems;
                state.cart_id = response._id;
                state.minicart_loading = false;
                localStorage.setItem('cart_id', response._id);
            });
            
        }
    },
    actions: {
        addToCart({commit, rootState}, item) {
            commit('addToCart', { twitch_id: rootState.user.twitch_id, item: item });
        },
        removeFromCart({commit, rootState}, item) {
            commit('removeFromCart', { twitch_id: rootState.user.twitch_id, item: item });
        },
        toggleMinicart({commit, rootState}) {
            commit('toggleMinicart', rootState.user.twitch_id);
        },
        loadCartData({commit, rootState}) {
            commit('loadCartData', rootState.user.twitch_id);
        },
    }
}


export default CartModule;