<template>
	<div class="cart-container">
		<h1 class="cart-header">Shopping Cart</h1>
		<v-divider></v-divider>
        <v-overlay v-model="loading" class="align-center justify-center">
            <v-progress-circular
                color="white"
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
		<v-list v-if="cartItems.length > 0 && !loading">
			<cart-item
				v-for="item in cartItems"
				:key="item.name"
				:item="item">
			</cart-item>
		</v-list>
		<span class="cart-empty-message" v-else>There are no items in your cart.</span>
		<v-divider></v-divider>
		<div class="cart-totals" v-if="cartItems.length > 0">
			<span class="cart-total">Total: ${{cartTotal}}</span>
		</div>
		<div class="cart-actions" v-if="cartItems.length > 0">
            <v-btn
                color="primary"
				@click="redirectToCheckout"
            >
                Check Out
            </v-btn>
		</div>
		<div v-if="errorMessage" style="display: block; color: red;" >
			<span class="error">Error: {{errorMessage}}</span>
		</div>
	</div>
</template>

<script>
import CartItemComponent from "@/modules/cart/components/CartItemComponent";

export default {
	components: {
		CartItem: CartItemComponent,
	},
	data() {
		return {
			errorMessage: '',
		}
	},
	computed: {
        cartItems() {
            return this.$store.state.cart.cart_items;
        },
		cartTotal() {
			var total = 0;
			for(var i = 0; i < this.cartItems.length; i++) {
				total += this.cartItems[i].price;
			}
			return total.toFixed(2);
		},
		loading() {
			return this.$store.state.cart.minicart_loading;
		}
	},
	created() {
	
	},
	methods: {
		redirectToCheckout() {
			var self = this;
			
			console.log(process.env);
			fetch(process.env.VUE_APP_API_URL + '/cart/checkout', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'API_KEY': process.env.VUE_APP_API_KEY,
					'API_SECRET': process.env.VUE_APP_API_SECRET
				},
				body: JSON.stringify({
					twitch_id: localStorage.twitch_id,
					cart_id: localStorage.cart_id,
					items: this.$store.state.cart.cart_items
				})
			}).then(res => {
				if (res.ok) return res.json();
				return res.json().then(json => Promise.reject(json));
			}).then(({ data }) => {
				// console.log(url);
				window.location = data.url;
			}).catch(e => {
				console.log('error', e);
				self.errorMessage = e.message;
			});
		}
	}
}
</script>

<style scoped>
.cart-totals {
	width: 100%;
	text-align: right;
	padding: 16px 32px;
}
.cart-empty-message {
	display: block;
	padding: 32px;
}
</style>