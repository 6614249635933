<template>
    <div class="login-page-container">
        <h1 class="login-header">Welcome to ChatFight</h1>
        <button
            class="twitch-login-btn"
            @click="loginWithTwitch">
            Login with Twitch
        </button>
    </div>
</template>


<script>

export default {
    data() {
        return {
            twitch_client_id: 'fexdrbzsuwr4ppkhvzzisuau4zvqih',
            twitch_client_secret: '5yg82jlvucgc0ywvf5qo2kxyngqlzc',
            twitch_user_data: {},
            twitch_code: '',
        }
    },
    created() {
        if(this.$route.query.code != null) {
            this.twitch_code = this.$route.query.code;
            this.authorizeTwitch();
        }
    },
    methods: {
        loginWithTwitch() {
            var url = process.env.VUE_APP_TWITCH_REDIRECT;
            window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=fexdrbzsuwr4ppkhvzzisuau4zvqih&redirect_uri=" + url + "/login&scope=user_read";
        },
        authorizeTwitch() {
            const self = this;
            var url = process.env.VUE_APP_TWITCH_REDIRECT + '/login';
            const twitchFetchURL = 
            'https://id.twitch.tv/oauth2/token?'
            + 'client_id=' + this.twitch_client_id
            + '&client_secret=' + this.twitch_client_secret
            + '&code=' + this.twitch_code 
            + '&grant_type=authorization_code'
            + '&redirect_uri=' + url;
            fetch(twitchFetchURL,{
                method: 'POST',
            })
            .then(response => {
                return response.json()
            })
            .then(json => {
                if(json.status == 400) {
                    return json;
                }
                self.loginAsUser(json);
            }).catch((e) => {
                console.log('twitch login error');
                console.log(e);
            });

        },
        loginAsUser(data) {
            var url = process.env.VUE_APP_API_URL;
            url += '/users/login';

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(data)
            })
            .then(response => {
                return response.json()
            }).then(async jsonResponse => {
                localStorage.token = jsonResponse.data.token;
                localStorage.display_name = jsonResponse.data.display_name;
                localStorage.twitch_id = jsonResponse.data.twitch_id;
                this.$store.dispatch('userLogin', jsonResponse.data);
                this.$router.push(jsonResponse.data.redirect_url);
            }).catch((e) => {
                console.log('user login error');
                console.log(e);
            });
        }
    }
}

</script>

<style>
.login-page-container {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.login-header {
    color: white;
}
.twitch-login-btn {
    background-color: #995BD5;
    width: 90%;
    max-width: 200px;
    height: 40px;
    color: white;
    border-radius: 4px; 
    align-self: center;
}
</style>