import EquipmentController from "../controllers/EquipmentController";
// import Vue from "vue";
const EquipmentModule = {
    state() {
        return {
            loading: false,
            equipped_items: {
                Head: '',
                Hands: '',
                Feet: '',
            },
            selected_slot: '',
        }
    },
    mutations: {
        selectSlot(state, type) {
            state.selected_slot = type;
        },
        equipInventoryItem(state,{item, type}) {
            state.equipped_items[type] = item;
        },
        async saveEquipment(state, data) {
            state.loading = true;
            await EquipmentController.SaveEquippedItems(state.equipped_items, data).then(() => {
                setTimeout(() => {state.loading = false}, 500);
            });
        },
        setEquippedItems(state, items) {
            state.equipped_items = {
                Head: items?.Head ? items.Head : '',
                Hands: items?.Hands ? items.Hands : '',
                Feet: items?.Feet ? items.Feet : '',
            };
        }
    },
    actions: {
        async selectSlot({ dispatch, commit }, type) {
            commit('selectSlot', type);
            await dispatch('openInventory', {type: type, twitch_id: localStorage.twitch_id});
        },
        async saveEquipment({ commit }, data) {
            await commit('saveEquipment', data);
        },
        setEquippedItems({commit}, items) {
            commit('setEquippedItems', items);
        }
    }
}

export default EquipmentModule;